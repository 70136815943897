<template>
  <div class="layout">
    <div class="container py-5">
      <div class="container-inner mt-5 mb-0 mb-md-5">
        <div class="row d-flex justify-content-between align-items-center py-5">
          <div class="col-lg-6 offset-lg-3 mt-5 mt-lg-0">
            <form @submit.prevent class="px-4 login-form" v-if="!passwordToken">
              <h2 class="text-center mb-5">Forgot Password?</h2>
              <p class="mb-5 text-center auth-explanation-text">No problem! We will send you an email allowing you to set a new password.</p>
              <div class="form-group">
                <label>Email</label>
                <input class="customForm" type="email" name="email" v-model='email_form.email' placeholder="Enter your email"/>
              </div>
              <div style="margin-bottom: 0 !important" class="form-group mb-0 mt-4 mb-4 pt-2">
                <button @click="sendEmail" type="button" class="button w-100">Send email</button>
              </div>
              <div v-if="errors?.length" class="errors-wrapper mt-4">
                  <p :key="error" v-for="error of errors" class="error-message">
                      {{error}}
                  </p>
              </div>
              <div class="d-flex login-links mt-4 justify-content-between flex-wrap">
                <router-link to="/login">I have my password</router-link>
                <router-link to="/register">Don't have an account yet?</router-link>
              </div>
            </form>
            <div class="d-flex flex-column align-items-center" v-else>
              <h1 class="font-primary success-heading mt-5 pt-5">Email sent!</h1>
              <img class="success-icon" :src="checkmarkIcon" alt="">
              <p class="text-center auth-explanation-text">Your request has been submitted successfully, please check your inbox at {{email_form.email}} for further instructions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
  import { defineComponent } from "vue";
  import config from '../../config';
  import checkmarkIcon from '../../assets/images/check-blue.svg';

  export default defineComponent({
    name: "ResetPassword",
    data: function() {
        return {
            checkmarkIcon: checkmarkIcon,
            errors: [],
            email_form: {
                email: ''
            },
            reset_form: {
                email: '',
                token: '',
                password: '',
                password_confirmation: ''
            },
            passwordToken: ''
        }
    },
    methods: {
        sendEmail() {
            this.errors = [];
            fetch(`${config.API}/api/v1/reset-password-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: this.email_form.email
                })
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data?.errors) {
                  this.errors = data.errors;
                }
                this.passwordToken = data.data.password_token
            })
            .catch(error => {
                console.log(error);
            })
        },
        // resetPassword() {
        //     fetch(`${config.API}/api/v1/reset-password`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             email: this.reset_form.email,
        //         })
        //     })
        //     .then(res => res.json())
        //     .then(data => {
        //         console.log(data);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        // }
    }
  });
</script>
